import React, {useEffect, useRef, useState} from "react";


export default function LondonistTab({active, onChange}) {
    const rootRef = useRef()
    const emailRef = useRef()
    const phoneRef = useRef()

    const [style, setStyle] = useState({})
    useEffect(() => {
        const rootWidth = rootRef.current.offsetWidth
        const emailRootWidth = emailRef.current.offsetWidth
        const emailWidth = emailRef.current.querySelector("span").offsetWidth
        const phoneRootWidth = phoneRef.current.offsetWidth
        const phoneWidth = phoneRef.current.querySelector("span").offsetWidth
        //  transform: translateX(4%) scaleX(0.15);
        if (active === "email") {
            //  transform: translateX(4%) scaleX(0.15);
            setStyle({
                transform: `translateX(10%) scaleX(0.8)`,
                width: emailWidth
            })
            return
        }

        setStyle({
            transform: `translateX(${emailRootWidth + 18 + ((phoneRootWidth / 10) / 2)}px) scaleX(0.8)`,
            width: phoneWidth
        })
    }, [active])

    return (
        <span ref={rootRef} className="londonist-tab">
            <span ref={emailRef} className={active === "email" ? "active" : ""} onClick={onChange("email")}>
                <span>
                    Email address
                </span>
            </span>
            <span ref={phoneRef} className={active === "phone" ? "active" : ''} onClick={onChange("phone")}>
                <span>
                    Phone number
                </span>
            </span>
            <i style={style}/>
        </span>
    )
}
