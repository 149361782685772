import React, {useEffect, useMemo, useState} from "react";
import {createFormData, useForm} from "./components/form/Form";
import APIService, {APIServiceMethod} from "./common/APIService";
import API from "./common/API";
import "./Auth.scss";
import {autoReadSMS, tryIt} from "./common/UTILS";
import Logo from "./assets/icon_white_logo.webp"
import DataForm from "./components/loginModal/DataForm";
import CodeInput from "./components/loginModal/CodeInput";
import {serializeFormData} from "./common/AuthUtils";
import londonistBlackLogo from "../londonist-black-logo.png"
import _ from "lodash"
import { toast } from 'react-toastify';
import ToastGenerator from "./common/ToastGenerator";


export const defaultLayout = -1
export const firstStepLayout = -1
export const loginLayout = 0
export const signupLayout = 1
export const forgotPasswordLayout = 2
export const emailVerifyLayout = 3
export const phoneVerifyLayout = 4
export const phoneVerifyForgotPasswordLayout = 5
export const emailVerifyLayoutForgotPasswordLayout = 6


const rightSideStyle = {
    border: '1px solid rgb(230 230 230)', borderRadius: 8
}
export default function LoginModal(pr) {
    const {showCm = true, open} = pr;

    const [active, setActive] = useState("email")

    const [layout, setLayout] = useState(defaultLayout)
    const [data, setData] = useState({
        phone: undefined
    })
    const [emailCode, setEmailCode] = useState('')
    const [phoneCode, setPhoneCode] = useState('')
    const [phoneVerifyStartAt, setPhoneVerifyStartAt] = useState(null)
    const [noUserFound, setNoUserFound] = useState(false)
    const [newUser, setNewUser] = useState(false)
    const [multiUser, setMultiUser] = useState(false)
    const {formProps, inputProps, actionProps, disabled, setDisabled, onResetForm} = useForm()


    useEffect(() => {
        if (open) return
        reset()
    }, [open, reset])

    function reset() {
        setEmailCode('')
        setPhoneCode('')
        setDisabled(false)
        onResetForm()
    }

    function loginSuccessfully(res) {
        if (!(res.data.access && res.data.refresh)) return
        onClose()
    }

    function handleSubmit(fd) {
        if (noUserFound) {
            setNewUser(false)
            setNoUserFound(false)
        }
        if (multiUser)
            setMultiUser(false)
        const formData = serializeFormData(fd)
        if (active === "email") {
            delete formData["phone"]
        } else {
            delete formData["email"]
        }


        if (layout === firstStepLayout) {
            checkUser(formData)
            return;
        }

        if (layout === forgotPasswordLayout) {
            showForgotPasswordLayout(formData)
            return;
        }

        if (layout === phoneVerifyLayout || layout === emailVerifyLayoutForgotPasswordLayout || layout === phoneVerifyForgotPasswordLayout) {
            phoneVerifyOrForgotPasswordReq(data, formData)
            return;
        }
        if (layout === loginLayout) {
            loginReq(formData)
            return
        }
        signupReq(formData)
    }

    function phoneVerifyOrForgotPasswordReq(data, formData) {
        if (disabled) return;
        setDisabled(true)
        const addData = layout === emailVerifyLayoutForgotPasswordLayout ? {"email_verification_code": formData} : {'sms_verification_code': formData}
        const newFormData = createFormData({...data, ...addData})
        const api = layout === phoneVerifyLayout ? API.Auth.Verification.api : API.Auth.ChangePassword.api;
        APIService(api, APIServiceMethod.POST, {
            body: newFormData
        }).then(res => {
            loginSuccessfully(res)
            loginReq(newFormData)
        }).catch(() => {
            setDisabled(false)
        })
    }

    function loginReq(data) {
        return APIService(API.Auth.Login.api, APIServiceMethod.POST, {body: data}).then(res => {
            loginSuccessfully(res)
        }).catch(() => {
            setLayout(defaultLayout)
        }).finally(() => {
            setDisabled(false)
        })
    }

    function checkUser(data) {
        return APIService(API.Auth.CheckUser.api, APIServiceMethod.POST, {body: data}).then(res => {
            if (res.data.message === "login") {
                setLayout(loginLayout)
                return res
            }
            if (res.data.message === "no user") {
                setNoUserFound(true)
                ToastGenerator("User Not Found" , "warning");
                return res
            }
            if (res.data.message === "multi user found") {
                setMultiUser(true)
                ToastGenerator('Unfortunately, we have detected an issue with your account.Please call our support +442072871912', "warning")
                return res
            }
            if (res.data.message === "no password") {
                setNewUser(true)
                setLayout(forgotPasswordLayout)
                return res
            }
            setLayout(forgotPasswordLayout)
            return res
        })
    }

    function signupReq(data) {
        APIService(API.Auth.Signup.api, APIServiceMethod.POST, {body: data}).then(res => {
            loginSuccessfully(res)


            initialCodeInput(data)
            //202
            if (res?.data?.message === "need email verification!") {
                showEmailVerificationLayout(data)
                return
            }
            //203
            if (res?.data?.message === "please login!") {
                handleChangeForm(loginLayout)
                setDisabled(false)
                return;
            }
            showPhoneVerificationLayout(data)
        }).catch((res) => {
            setDisabled(false)
        })
    }

    function initialCodeInput(objData) {
        setEmailCode('')
        setPhoneCode('')
        setData(objData)
    }


    async function reqEmailCodeVerification(da = data) {
        return APIService(API.Auth.EmailVerification.api, APIServiceMethod.POST, {
            body: createFormData(da)
        })
    }

    async function reqPhoneCodeVerification(da = data) {
        return APIService(API.Auth.SmsVerification.api, APIServiceMethod.POST, {
            body: createFormData(da)
        })
    }

    function showForgotPasswordLayout(da = data) {
        autoReadSMS((code) => {
            try {
                if (code.length === 6) {
                    setPhoneCode(code)
                }
            } catch {
            }
        })
        APIService(API.Auth.ForgetPassword.api, APIServiceMethod.POST, {body: da})
            .then(res => {
                loginSuccessfully(res)
                setPhoneVerifyStartAt(new Date())
                initialCodeInput(da)

                if (!_.isEmpty(da.email)) {
                    setLayout(emailVerifyLayoutForgotPasswordLayout)
                    return
                }
                setLayout(phoneVerifyForgotPasswordLayout)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    onResetForm()
                    setLayout(signupLayout)
                }
                if (err.response.status === 400) {
                    // console.log(err.response.data.notification.message); // error body
                    ToastGenerator("Error : " + err.response.data.notification.message, err.response.data.notification.type)
                }
            }).finally(() => {
            setDisabled(false)
        })
    }

    function showEmailVerificationLayout(da = data) {
        reqEmailCodeVerification(da).then(res => {
            setPhoneVerifyStartAt(new Date())
            setLayout(emailVerifyLayout)
        }).finally(() => {
            setDisabled(false)
        })
    }

    function showPhoneVerificationLayout(da = data) {
        autoReadSMS((code) => {
            try {
                if (code.length === 6) {
                    setPhoneCode(code)
                }
            } catch {
            }
        })
        setDisabled(true)
        reqPhoneCodeVerification(da).then(res => {
            setPhoneVerifyStartAt(new Date())
            setLayout(phoneVerifyLayout)
        }).catch(() => {
            setLayout(defaultLayout)
        }).finally(() => {
            setDisabled(false)
        })
    }

    const isBaseLayout = (layout === loginLayout || layout === signupLayout)

    function handleChangeForm(newLayout) {
        if (!isBaseLayout || layout === newLayout || disabled) return
        setLayout(newLayout)
        tryIt(onResetForm)
    }

    const showCloseButton = useMemo(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const closeBtn = Object.fromEntries(urlSearchParams.entries()).closebtn;
        return !!closeBtn
    }, [window.location.search])

    function onClose() {
        try {
            const urlSearchParams = new URLSearchParams(window.location.search)
            const redirect = Object.fromEntries(urlSearchParams.entries()).redirect
            if (!redirect) throw ""
            window.location.replace(redirect);
        } catch {
            window.history.back()
        }
    }


    const isVerifyCodeLayout = layout === phoneVerifyForgotPasswordLayout || layout === phoneVerifyLayout || layout === emailVerifyLayout || layout === emailVerifyLayoutForgotPasswordLayout


    return (<div className={"londonistLoginModalRoot d-flex flex-column justify-content-center align-items-center"}>
        <div
            className="d-block d-md-none mb-3"
            style={{
                width: "50%"
            }}>
            <img src={londonistBlackLogo} alt="londonist Black Logo" style={{width: '100%'}}/>
        </div>
        <div id="login-modal" className={`d-flex`}>
            <div
                className={`col-4 col-lg-5 col-xxl-6 d-none d-md-flex justify-content-center londonistLoginModalLeftContainer`}>
                    <span className={'h4 d-flex flex-column align-items-start  ps-3 color_ffffff lh-base'}>
                        Welcome to,<br/>
                        <div className={"col-9 mt-2 pt-1"}>
                            <div className="logo">
                                <img alt="logo" src={Logo} style={{width: "100%", height: 'auto'}}/>
                            </div>
                        </div>
                    </span>
            </div>
            <div
                className={'d-flex flex-fill justify-content-center position-relative londonistLoginModalRightRoot'}>
                <div className={`londonistLoginModalRightContainer d-flex flex-column p-3`}>
                    {showCloseButton && <div
                        role={"button"}
                        className={`londonistLoginModalCloseButton p-1`}
                        onClick={() => {
                            onClose()
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                             style={{width: 15}}>
                            <path
                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                        </svg>
                    </div>}
                    <div
                        role={"button"}
                        className={`londonistLoginModalBackButton color_444444 p-1 ${!isVerifyCodeLayout ? 'd-none' : 'd-flex align-items-center'}`}
                        onClick={() => {
                            reset()
                            setLayout(loginLayout)
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{width: "15px"}}>
                            <path
                                d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                            />
                        </svg>
                        Back
                    </div>
                    <div
                        className={` pt-5 pt-md-0 d-flex flex-column justify-content-center w-100 align-self-center h-100  my-4`}>
                        <DataForm
                            onLayoutChange={(layout) => {
                                setLayout(layout)
                                onResetForm()
                            }}
                            active={active}
                            setActive={setActive}
                            noUserFound={noUserFound}
                            newUser={newUser}
                            setNewUser={setNewUser}
                            setNoUserFound={setNoUserFound}
                            multiUser={multiUser}
                            setMultiUser={setMultiUser}
                            onSubmit={handleSubmit}
                            actionProps={actionProps}
                            formProps={formProps}
                            layout={layout}
                            inputProps={inputProps}/>
                        {(layout === phoneVerifyLayout || layout === phoneVerifyForgotPasswordLayout) && <CodeInput
                            startAt={phoneVerifyStartAt}
                            code={phoneCode}
                            disabled={disabled}
                            actionProps={actionProps}
                            onResendTheCode={layout === phoneVerifyLayout ? showPhoneVerificationLayout : showForgotPasswordLayout}
                            details={(<div
                                className={'w-100 h5 mb-3 d-flex flex-column'}>
                                            <span className={"h4 fw-bold mb-4 pb-2"}>
                                                Verify Your Phone
                                            </span>
                                <span className={"h6 mb-4 pb-3"}>
                                                Code Sent to: <span
                                    className={"ps-2 color_8e8e8e"}>{data?.phone}</span>
                                            </span>
                            </div>)}
                            onCodeChange={(code) => {
                                setPhoneCode(code)
                                if (code.length === 6) {
                                    handleSubmit(code)
                                }
                            }}/>}
                        {(layout === emailVerifyLayout || layout === emailVerifyLayoutForgotPasswordLayout) &&
                            <CodeInput
                                isEmail={true}
                                code={emailCode}
                                disabled={disabled}
                                actionProps={actionProps}
                                startAt={phoneVerifyStartAt}
                                onResendTheCode={showEmailVerificationLayout}
                                details={(<div
                                    className={'w-100 h5 mb-3 d-flex flex-column'}>
                                            <span className={"h4 fw-bold mb-4 pb-2"}>
                                                Verify Your Email
                                            </span>
                                    <span className={"h6 mb-4 pb-3"}>
                                                Code Sent to:
                                                <span
                                                    className={"ps-2 color_8e8e8e"}>{data?.email}</span>
                                            </span>
                                </div>)}
                                onCodeChange={(code) => {
                                    setEmailCode(code)
                                    if (code.length === 6) {
                                        setData((data) => ({
                                            ...data, email_verification_code: code
                                        }))
                                        handleSubmit(code)
                                    }
                                }}/>}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

