import React from "react";
import Input from "../form/input/Input";
import Validator from "../../common/Validator";
import Button from "../button/Button";
import Form from "../form/Form";
import PhoneInput from "../form/phoneInput/PhoneInput";
import {firstStepLayout, forgotPasswordLayout, loginLayout, signupLayout} from "../../Auth";
import LondonistTab from "../LondonistTab";


export default function DataForm({
                                     layout,
                                     onLayoutChange,
                                     onSubmit,
                                     newUser,
                                     setNewUser,
                                     formProps,
                                     noUserFound,
                                     setNoUserFound,
                                     actionProps,
                                     inputProps,
                                     active,
                                     setActive,
                                     multiUser,
                                     setMultiUser,
                                 }) {
    const isLoginLayout = layout === loginLayout;
    const isSignupLayout = layout === signupLayout;
    const isForgotPasswordLayout = layout === forgotPasswordLayout;
    const isFirstLayout = layout === firstStepLayout;
    const isBaseLayout = isFirstLayout || isLoginLayout || isSignupLayout || isForgotPasswordLayout;

    function handleTabChange(tab) {
        return () => {
            if (noUserFound) {
                setNoUserFound(false)
                setNewUser(false)
            }
            if (multiUser) {
                setMultiUser(false)
            }
            setActive(tab)
        }
    }

    return (
        <Form
            name="login_form"
            className={`${!isBaseLayout ? 'd-none' : 'd-flex'} londonistLoginModalDataForm`}
            autoDisable={true}
            onSubmit={onSubmit} {...formProps}>
            <span className={'d-flex flex-column w-100 pb-lg-3 mb-4 h5 fw-bold color_444444'}
                  style={{textAlign: 'left'}}>
                {(isLoginLayout || isFirstLayout) ?
                    'Log in to your account' :
                    isSignupLayout ?
                        'Register for an account' :
                        isForgotPasswordLayout ?
                            newUser ? 'Set Your Password' : 'Reset Password' : ''}
                {
                    isForgotPasswordLayout &&
                    <span className={"h7 lh-base fw-normal pt-2"}>
                        {
                            newUser ?
                                "To access your portal information, you need to set a password using the relevant details below. Once you have set your password, you will need to complete the verification process to gain access to your account." :
                                "If you can't remember your password, don't worry! You can reset it by filling in the required details below. Once you change your password, we'll send you an verification code to make sure it's really you. Simply enter the code on the next step to complete the process."
                        }
                    </span>
                }
            </span>

            {
                (isFirstLayout) &&
                <LondonistTab active={active} onChange={handleTabChange}/>
            }
            <div className={`${isSignupLayout ? 'd-flex' : 'd-none'} flex-column w-100`}>
                <div
                    className={`mb-4 mb-md-3 w-100 d-flex flex-column flex-md-row`}>
                    <Input
                        placeholder={"Name"}
                        name={'student_firstname'}
                        formGroupClass={'me-md-2'}
                        required={isSignupLayout}
                        autoComplete={"given-name"}
                        {...inputProps}/>
                    <Input
                        placeholder={"Surname"}
                        name={'student_lastname'}
                        formGroupClass={'ms-md-2 mt-4 mt-md-0'}
                        required={isSignupLayout}
                        autoComplete={"family-name"}
                        {...inputProps}/>
                </div>
            </div>
            <div className={`${(isSignupLayout || active === "email") ? 'd-flex' : 'd-none'} flex-column w-100`}>
                <Input
                    placeholder="Email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    formGroupClass={`w-100 ${isSignupLayout ? "mb-4 mb-md-3" : ""}`}
                    validator={active === "email" ? Validator.isEmail : undefined}
                    required={isSignupLayout || active === "email"}
                    {...inputProps}
                    disabled={!isFirstLayout && !isSignupLayout}/>
            </div>
            <div className={`${(isSignupLayout || active === "phone") ? 'd-flex' : 'd-none'} flex-column w-100`}>
                <PhoneInput
                    id="phone"
                    placeholder="Phone Number"
                    name="phone"
                    className="w-100"
                    variant="underline"
                    required={isSignupLayout || active === "phone"}
                    {...inputProps}
                    disabled={!isFirstLayout && !isSignupLayout}/>
            </div>
            {
                (!isFirstLayout && !isSignupLayout) &&
                <small
                    role="button"
                    className="pl-1 pt-2 caption w-100 text-start"
                    onClick={() => {
                        onLayoutChange(firstStepLayout)
                    }}
                >
                    Change entered {active === "email" ? "Email" : "Phone Number"}
                </small>
            }
            <span
                className={`w-100 h6 text-start mt-4 mt-md-3 color_444444 ${isForgotPasswordLayout ? 'pb-2' : ''}`}>
                    {
                        isForgotPasswordLayout &&
                        "New Password"
                    }
            </span>
            <Input
                formGroupClass={`w-100 ${isFirstLayout ? "d-none" : ""}`}
                placeholder={isForgotPasswordLayout ? "New Password" : "Password"}
                id="password"
                name="password"
                type={"password"}
                aira-label="password"
                autoComplete={isLoginLayout ? "current-password" : "new-password"}
                validator={!isFirstLayout ? [Validator.isPassword] : undefined}
                required={!isFirstLayout}
                {...inputProps}/>
            {
                (isLoginLayout) &&
                <Button variant="link"
                        onClick={() => onLayoutChange(forgotPasswordLayout)}
                        className="mt-3 py-0"
                        {...actionProps}
                        loading={false}
                        spanClassName="color_444444 text-decoration-underline">
                    Forgotten Password
                </Button>
            }


            {(noUserFound) &&
                <span className="ps-1 h6 text-start lh-base">
                    {
                        active === "email" ?
                            (<>
                                User not found, Please call to our support <a className="px-1" href="tel:+442072871912"><b
                                className="d-block">+442072871912</b></a>
                            </>) :
                            (<>
                                User not found, Please try with <b role="button" className="px-1"
                                                                   onClick={handleTabChange("email")}>Email</b> or
                                call to our support <a className="px-1" href="tel:+442072871912"><b
                                className="d-block">+442072871912</b></a>
                            </>)
                    }
                </span>
            }

            {(multiUser) &&
                <span className="ps-1 h6 text-start lh-base">
                    There is a problem with your account, please contact the number below <a className="px-1"
                                                                                             href="tel:+442072871912">
                    <b>+442072871912</b></a>  or send an email to <a className="px-1"
                                                                     href="mailto:support@londonist.co.uk">
                    <b>support@londonist.co.uk</b></a>
                </span>
            }


            <div className="px-5 w-100 pt-lg-3">
                <Button type="submit"
                        className="w-100 text-capitalize mt-4"
                        spanClassName="h6 py-1"
                        {...actionProps}>
                    {isForgotPasswordLayout ? newUser ? "Set Password":"Change Password" : !isSignupLayout ? "Login" : "Signup"}
                </Button>
            </div>

            {/*<div*/}
            {/*    className="pt-5 mt-4 d-flex flex-column text-center align-items-center justify-content-center w-100">*/}
            {/*    <span className="d-flex h7">*/}
            {/*        {*/}
            {/*            isLoginLayout ?*/}
            {/*                "Don't have an account?" :*/}
            {/*                "Already a member?"*/}
            {/*        }*/}
            {/*    </span>*/}
            {/*    <Button*/}
            {/*        role="button"*/}
            {/*        variant="outline-primary"*/}
            {/*        normalRadius={true}*/}
            {/*        className="py-2 mt-2"*/}
            {/*        spanClassName="h7"*/}
            {/*        onClick={() => onLayoutChange(isLoginLayout ? signupLayout : loginLayout)}>*/}
            {/*        {*/}
            {/*            isLoginLayout ?*/}
            {/*                "Sign Up" :*/}
            {/*                "Login Now"*/}
            {/*        }*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </Form>
    )
}
