export function autoReadSMS(cb) {
// used AbortController with setTimeout so that WebOTP API (Autoread sms) will get disabled after 1min
    const signal = new AbortController();

    setTimeout(() => {
        signal.abort();
    }, 170000);

    async function main() {
        if ('OTPCredential' in window) {
            try {
                if (navigator.credentials) {
                    try {
                        await navigator.credentials
                            .get({abort: signal, otp: {transport: ['sms']}})
                            .then(content => {
                                if (content && content.code) {
                                    cb(content.code);
                                }
                            });
                    } catch (e) {
                    }
                }
            } catch (err) {
            }
        }
    }

    main();
}

export const getSafe = (func, defaultVal = undefined) => {
    try {
        return func()
    } catch {
        return defaultVal
    }
}

export const tryIt = getSafe

export const getBaseDomain = () => {
    if (window.location.host.includes("localhost")) {
        return "localhost"
    }
    return process.env.REACT_APP_BASE_DOMAIN
}
