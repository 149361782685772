
const API = {
    Residences: {
        api: '/campuses',
        create: () => API.Residences.api,
        Filters: {
            api: '/filters'
        },
        Areas: {
            api: '/featured_areas'
        }
    },
    Residence: {
        api: '/campuses/slug',
        create: (slug) => API.Residence.api + `/${slug}`,
        RelatedCampus: {
            api: '/related_campuses',
        }
    },
    Courses: {
        api: '/courses',
        create: (slug) => API.Courses.api + `/${slug}`
    },
    Suggest: {
        api: '/suggest',
        create: (keyword) => API.Suggest.api + `/${keyword}`

    },
    AdditionalServices: {
        api: '/additional_services'
    },
    Auth: {
        Login: {
            api: '/auth/login'
        },
        CheckUser: {
            api: '/auth/check_user'
        },
        Signup: {
            api: '/auth/signup'
        },
        Verification: {
            api: '/auth/verification'
        },
        EmailVerification: {
            api: '/auth/email_verification'
        },
        SmsVerification: {
            api: '/auth/sms_verification'
        },
        ForgetPassword: {
            api: '/auth/forget_password'
        },
        ChangePassword: {
            api: '/auth/change_password'
        },
        RefreshToken: {
            api: '/auth/refresh_token'
        }
    },
    Book: {
        api: '/book',
        create: (slug) => API.Book.api + `/${slug}`,
        BookFee: {
            api: '/book_fee',
        }
    },
    Countries: {
        api: '/countries'
    },
    User: {
        Get: {
            api: '/auth/profile'
        },
        Favorites: {
            api: 'favorites'
        },
        TempBook: {
            api: '/temp_book'
        }
    },
    Payment: {
        Stripe: {
            Charge: {
                api: '/stripe/charge'
            }
        },
        Paypal: {
            Charge: {
                api: '/paypal/charge'
            }
        },
        Flywire: {
            Charge: {
                api: '/flywire/charge'
            }
        }
    },
    News: {
        api: '/news',
    },
    Promotions: {
        api: '/promotions',
    },
    NewsLetter: {
        api: '/newsletter',
    },
    Lead: {
        api: '/lead',
    },
    Enquire: {
        api: '/enquire',
    },
    Rate: {
        api: '/rate',
    },
}



export default API
