import React, {useMemo} from "react";
import {Button as RButton, Spinner} from "react-bootstrap";

export const BUTTON_ANIMATION = {
    default: 'default'
}

function Button({
                    onClick,
                    className = "",
                    variant,
                    loading,
                    loadingVariant,
                    normalStyle,
                    color,
                    animation,
                    secondLayout,
                    size,
                    normalRadius,
                    spanClassName = '',
                    justifyContent = 'justify-content-center',
                    noWrap = true,
                    ...props
                }, ref) {

    const animationClasses = useMemo(() => {
        if (animation === BUTTON_ANIMATION.default || animation === undefined) {
            return "defaultAnimation"
        }
        return ""
    }, [animation, props.children])

    return (
        <RButton
            role="button"
            ref={ref}
            variant={variant}
            onClick={onClick}
            size={size}
            className={`d-flex ${justifyContent} align-items-center ${normalRadius ? 'normalRadius' : ''} ${animationClasses} ${noWrap ? 'text-nowrap' : ''} londonistButton ${className}`}
            {...props}>
            {
                loading &&
                <Spinner size="sm" animation="border" variant={loadingVariant || variant} className={'me-2'}/>
            }
            <span className={`d-flex align-items-center ${spanClassName}`}>
                {props.children}
            </span>
        </RButton>
    )
}


export default React.forwardRef(Button)
