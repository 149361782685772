import React from 'react';
import './App.css';
import Auth from "./londonistAuth/Auth";
import {LondonistAuthContainer} from "./londonistAuth/LondonistAuthContext";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <LondonistAuthContainer>
            <div className="App">
                <Auth/>
                <span className="h6 color_808080" style={{marginTop: "16px"}}>
                    @ all right received to Londonist DMC 2023
                </span>
            </div>
            <ToastContainer/>
        </LondonistAuthContainer>
    );
}

export default App;
