import React, {useState} from "react";
import PropTypes from "prop-types";
import PhoneInputBase from 'react-phone-input-2'
import {getUserCountryCode} from "../../../userDataUtils";


function PhoneInput({
                        id,
                        className = '',
                        title,
                        errorLabel = "Phone Number",
                        validatedForm,
                        disabled,
                        name,
                        variant,
                        required,
                        placeholder,
                        ...props
                    }) {
    const [phone, setPhone] = useState('')
    const [country, setCountry] = useState(getUserCountryCode())

    const isValid = required && phone.length > 0 ? phone.length > 6 : true
    return (
        <div
            id={id}
            className={`w-100 londoistPhoneInput`}
            country={country}
            valid={(isValid && validatedForm) ? '' : undefined}>
            <PhoneInputBase
                containerClass={`${className} londoistPhoneInput`}
                has_error={phone.length > 6 ? '' : undefined}
                disabled={disabled}
                inputProps={{
                    placeholder,
                    name,
                    required,
                    has_error: !isValid ? '' : undefined,
                    valid: validatedForm && isValid ? '' : undefined,
                    autoComplete: 'tel'
                }}
                placeholder={errorLabel}
                country={country}
                value={phone}
                onChange={(newPhone, {countryCode, dialCode,} = {}) => {
                    if (country === countryCode) {
                        setPhone(newPhone)
                        return
                    }
                    setCountry(countryCode)
                    if (phone.length > 4)
                        setPhone(dialCode)
                }} {...props}
            />
        </div>
    )
}


PhoneInput.prototype = {
    name: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["underline", "custom"])
}

export default PhoneInput
