import React, {useContext, useEffect, useState} from "react";
import {PP} from "./TYPE";
import axios from "./axios";

export const defaultState = {
    accessToken: "",
    refreshToken: "",
}

export const LondonistAuthContext = React.createContext(defaultState)


export function LondonistAuthContainer({
                                           api,
                                           children
                                       }: PP & { api?: string }) {

    const [accessToken, setAccessToken] = useState<string>("")
    const [refreshToken, setRefreshToken] = useState<string>("")


    useEffect(() => {
        axios.interceptors.response.use(function (response) {
            console.log("slkfakaskfjakj", response)
            return response;
        }, function (error) {
            return Promise.reject(error);
        });
    }, [])


    return (
        <LondonistAuthContext.Provider
            value={{
                accessToken: accessToken,
                refreshToken: refreshToken,
            }}>
            {children}
        </LondonistAuthContext.Provider>
    )
}


export function useLondonistAuthContext() {
    return useContext(LondonistAuthContext)
}
