import {tryIt} from "./UTILS";
import _ from "lodash"

export const serializeFormData = (formData = {}) => {
    if (!_.isObject(formData))
        return formData
    const fd = {...formData}
    tryIt(() => {
        fd.phone = fd.phone.replace(/ /g, '')
    })
    tryIt(() => {
        fd.email = fd.email.replace(/ /g, '')
    })
    tryIt(() => {
        fd.password = fd.password.replace(/ /g, '')
    })

    if (fd.phone.length < 6) {
        delete fd.phone
    }

    return fd
}
