import _ from 'lodash'

const val = {
    isRequired: [[isRequired, "Field Required"]],
    isEmail: [[isEmail, "Invalid Email"]],
    isPhoneNumber: [[isPhoneNumber, "Invalid Phone"]],
    isPassword: [
        [isPassword1, "Invalid password one word"],
        [isPassword2, "Invalid password min word"],
    ],
}

export default val

export function isEmail(email) {
    return (
        !isRequired(email) ||
        RegExp(
            '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$',
        ).test(email)
    )
}

export function isRequired(value) {
    return !_.isEmpty(value)
}

export function isPhoneNumber(mobileNumber) {
    return !isRequired(mobileNumber) || RegExp('^(\\+\\d{1,3}[- ]?)?\\d{10}$').test(mobileNumber)
}

export function isPassword(pass) {
    return !isRequired(pass) || (inRange(pass, 8) && RegExp('.*[a-zA-Z]+.*').test(pass))
}

function isPassword1(pass) {
    return !isRequired(pass) || RegExp('.*[a-zA-Z]+.*').test(pass)
}

function isPassword2(pass) {
    return !isRequired(pass) || inRange(pass, 8)
}

export function inRange(value, min = undefined, max = undefined) {
    return !(
        (!_.isUndefined(min) && value.length < min) ||
        (!_.isUndefined(max) && value.length > max)
    )
}
