import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash'
import {Form as BootstrapForm} from "react-bootstrap";
import ToastGenerator from "../../common/ToastGenerator";
import {tryIt} from "../../common/UTILS";


export function createFormData(data) {
    return data
    const formData = new FormData()
    _.forEach(data, (value, key) => {
        formData.append(key, value)
    })
    return formData
}


export function getFormData(formName) {
    try {
        const inputs = [
            ...(document.forms[formName].querySelectorAll("* input") || []),
            ...(document.forms[formName].querySelectorAll("* textarea") || [])
        ];
        const data = {};
        _.forEach(inputs, (el) => {
            const value = [el.name, el.type === "checkbox" ? el.checked : el.value];
            if (!value[0])
                return;
            if (data[value[0]]) {
                if (_.isArray(data[value[0]])) {
                    data[value[0]].push(value[1]);
                    return;
                }
                data[value[0]] = [data[value[0]], value[1]];
                return;
            }
            data[value[0]] = value[1];
        });

        const returnData = {}
        _.forEach(data, (val, key) => {
            try {
                const match = key.match('(.+)__(.+)');
                if (!(match[1] && match[2])) {
                    throw ""
                }
                returnData[match[1]] = returnData[match[1]] || {};
                returnData[match[1]][match[2]] = val
            } catch {
                returnData[key] = val
            }
        })
        return returnData
    } catch {
        return {}
    }
}

export default function Form({
                                 name = 'form',
                                 display = 'd-flex',
                                 autoComplete = true,
                                 disabled,
                                 setDisabled,
                                 className = '',
                                 alwaysCallSubmit,
                                 autoDisable,
                                 validatedForm,
                                 setValidatedForm,
                                 onSubmit,
                                 ...props
                             }) {
    const ref = useRef()
    const [validated, setValidated] = useState(false);

    function handleSubmit(event, alwaysCallSub = alwaysCallSubmit) {
        try {
            event?.preventDefault();
            event?.stopPropagation();
            if (!Boolean(event?.nativeEvent.submitter.attributes["type"]))
                return;
        } catch {
        }

        tryIt(() => setValidatedForm(true));

        if (autoDisable)
            setDisabled(true);
        setTimeout(() => {
            const check = checkValidate();
            try {
                if (check === true || alwaysCallSub) {
                    const data = getFormData(name);
                    tryIt(() => onSubmit(
                        createFormData(data),
                        {
                            data,
                            event: event,
                            valid: check,
                            redirect: tryIt(() => Boolean(event.nativeEvent.submitter.attributes["redirect"]), false)
                        }));
                    return;
                }
            } catch {
            }
            tryIt(() => setDisabled(false));
            const inputName = _.isString(check) ? `, check ${check}` : ''
            ToastGenerator("Form is invalid" + inputName, "warning");
        }, 800);
    }

    useEffect(() => {
        if (!validatedForm) {
            setValidated(false);
            return;
        }
        checkValidate();
    }, [validatedForm]);

    function checkValidate() {
        let valid = true;
        let elName;
        _.forEach(ref.current, el => {
            if (el.attributes.has_error && el.type !== "hidden") {
                valid = false;
                elName = el.attributes?.["error-label"]?.value || el.name;
                return false
            }
        });
        setValidated(valid);
        return elName || valid;
    }

    useEffect(() => {
        setTimeout(() => {
            if (ref?.current)
                ref.current.onSubmit = handleSubmit
        }, 1000)
    }, [ref])

    return (
        <BootstrapForm
            name={name}
            ref={ref}
            noValidate
            className={`flex-wrap ${display} ${className}`}
            onSubmit={handleSubmit}
            autoComplete={autoComplete ? 'on' : 'disabled'}
            validated={validated}
            {...props}>
            {props.children}
        </BootstrapForm>);
}

export function useForm({validatable = true, alwaysCallSubmit, autoDisable = true} = {}) {
    const [validatedForm, setValidatedForm] = useState(false);
    const [disabled, setDisabled] = useState(false);

    function handleValidatedForm(val) {
        if (!validatable)
            return
        setValidatedForm(val)
    }

    function onResetForm() {
        handleValidatedForm(false);
        setDisabled(false);
    }

    return {
        formProps: {
            alwaysCallSubmit,
            autoDisable,
            validatedForm,
            setValidatedForm: handleValidatedForm,
            disabled,
            setDisabled
        },
        inputProps: {validatedForm, disabled},
        actionProps: {disabled, loading: disabled},
        disabled,
        setDisabled,
        onResetForm
    };
}
