import Axios from 'axios'
import qs from 'qs';
import {getBaseDomain, getSafe} from "./UTILS";
import ToastGenerator from "./ToastGenerator";

export const APIServiceMethod = {
    GET: 'GET', POST: 'POST', PUT: 'PUT', DELETE: 'DELETE', PATCH: 'PATCH',
}

export const CONFIG = {
    baseUrl: {
        serverUrl: '', mock: '',
    }, clientSecret: {
        headerName: 'client-secret', value: '',
    }, clientId: {
        headerName: 'client-id', value: '',
    }, accessToken: {
        localStorageName: 'access_token', headerName: 'Authorization', prefix: 'Bearer ',
    }, refreshToken: {
        active: false, url: '', method: 'POST', token: {
            localStorageName: 'refresh_token', headerName: 'refresh-token', prefix: '',
        }, onStatus: [401, 403],
    },
}


let tryToRefreshToken = false
let tryToRefreshTokenCont = 0

/*contactUsForm handling refresh token on certain statuses */
const refreshTokenHandler = () => {
    const myHeader = {
        // [CONFIG.refreshToken.token.headerName]: getRefreshToken(),
    }

    // SyncAPIService(
    //   CONFIG.refreshToken.url,
    //   CONFIG.refreshToken.method,
    //   {
    //     onSuccess: (res, extraData) => {
    //       // save access token and refresh token later
    //     },
    //     onFail: (err, extraData) => {
    //       // some activities
    //     },
    //   },
    //   {
    //     headers: myHeader,
    //   },
    //   {
    //     deleteAccessTokenAfterRequest_fail: true,
    //     useClientSecret: true,
    //     disableRefreshToken: true,
    //   },
    // )
}

/**
 * Full useable async APIService (v1)
 * @param {String} url API call url. This will added to the base url
 * @param {String} method API call methods. Should be `GET`, `POST`, `PUT`, `DELETE` or `PATCH`
 * @param callback An object that should have `onSuccess` function and `onFail` function.contactUsForm passing extra data to the functions, add `extraData` field. Functions receive this data as second props
 * @param requestData An object which use for passing `headers`, `body`, `params` to request.contactUsForm add other axios options, put them in `options`
 * @param requestOptions An object for change APIService behavior.
 */
export const APIService = async (url, method, requestData = {
    headers: {}, body: {}, params: {}, options: {},
}, requestOptions = {
    toast: {
        success: '', fail: '',
    },
    useClientSecret: false,
    disableClientId: false,
    disableLogOnError: false,
    deleteAccessTokenAfterRequest_success: false,
    deleteAccessTokenAfterRequest_fail: false,
    disableRefreshToken: false,
},) => {
    let api, headers = {}, body = {}, params = {}, options = {}


    /* input validation */
    /* url validation */
    if (url === null || url === undefined) {
        throw 'APIService ERROR: url is not defined correctly'
    }

    /* method validation */
    if (method !== APIServiceMethod.GET && method !== APIServiceMethod.POST && method !== APIServiceMethod.PUT && method !== APIServiceMethod.DELETE && method !== APIServiceMethod.PATCH) {
        throw 'API CALL ERROR: API call method is not defined correctly'
    }

    if (requestData) {
        if (requestData.headers) {
            headers = requestData.headers
        }
        if (requestData.body) {
            body = requestData.body
        }
        if (requestData.params) {
            params = requestData.params
        }
        if (requestData.options) {
            options = requestData.options
        }
    }

    /* modify request headers */
    // if (requestOptions.useClientSecret) {
    //     headers[CONFIG.clientSecret.headerName] = CONFIG.clientSecret.value
    // }


    /* if client id exist in CONFIG, we send it by request */
    if (CONFIG.clientId.value !== '' && !requestOptions.disableClientId) {
        headers[CONFIG.clientId.headerName] = CONFIG.clientId.value
    }

    if (requestOptions.useClientSecret) {
        // const accessToken = getAccessToken()
        // // headers['Access-Control-Allow-Origin'] = '*'
        // headers[CONFIG.accessToken.headerName] = accessToken ? (CONFIG.accessToken.prefix + getAccessToken()) : ''
    }
    /* create axios instance */
    api = Axios.create({
        baseURL: process.env.REACT_APP_BASE_API,
        headers: headers,
        params: params,
        data: method !== 'GET' ? body : {}, ...options,
    })

    let response
    switch (method) {
        case APIServiceMethod.GET:
            response = api.get(url, {
                paramsSerializer: function (params) {
                    return qs.stringify(params, {arrayFormat: 'repeat'})
                },
            })
            break

        case APIServiceMethod.POST:
            response = api.post(url, body)
            break

        case APIServiceMethod.PUT:
            response = api.put(url, body)
            break

        case APIServiceMethod.DELETE:
            response = api.delete(url)
            break

        case APIServiceMethod.PATCH:
            response = api.patch(url, body)
            break

        default:
            return
    }
    return await response
        .then((response) => {
            if (requestOptions.toast && requestOptions.toast.success !== '') {
                ToastGenerator(requestOptions.toast.success, 'success')
            }
            if (response.data && response.data?.notification) {
                ToastGenerator(response.data.notification?.message, response.data.notification?.type, {
                    autoClose: response.data.notification?.autoClose
                })
            }
            if (requestOptions.deleteAccessTokenAfterRequest_success) {
                // removeAccessToken()
            }

            console.log("check token", response?.data?.access)
            if (response?.data?.access || response?.data?.refresh) {
                console.log("new token received", response?.data?.access)
                const {access, refresh, student_portal_token} = response?.data
                const domain = getBaseDomain()
                console.log("base domain", domain)
                document.cookie = `access=${access};path=/;domain=${domain}`;
                document.cookie = `refresh=${refresh};path=/;domain=${domain}`;
                console.log("cookie set", document.cookie)
                // getStore().dispatch(updateToken(access, refresh, student_portal_token))
            }
            return response
        })
        .catch((err) => {
            const status = getSafe(() => err.response.status)
            if (status === 401) {
                // const refreshToken = getRefreshToken()
                // if (!refreshToken) {
                //     logout()
                // } else {
                //     if (!tryToRefreshToken) {
                //         tryToRefreshToken = true
                //         APIService(API.Auth.RefreshToken.api, APIServiceMethod.POST, {
                //             body: {
                //                 refresh: getRefreshToken()
                //             }
                //         }).catch(() => {
                //             logout()
                //         }).then(() => {
                //             tryToRefreshToken = false
                //         })
                //     }
                // }
            }


            if (err?.response?.data?.notification) {
                ToastGenerator(err?.response?.data?.notification?.message, err?.response?.data?.notification?.type, {
                    autoClose: err?.response?.data?.notification?.autoClose
                })
            }


            // /* take a rest and then fix error :)) */
            // if (requestOptions.toast && requestOptions.toast.fail !== '') {
            //     CustomToast(requestOptions.toast.fail, 'error')
            // }
            // if (err && err.data && err.data.message) {
            //     CustomToast(err.data.message.msg, err.data.message.type)
            // }
            // if (requestOptions.deleteAccessTokenAfterRequest_fail) {
            //     // removeAccessToken()
            // }
            // if (!requestOptions.disableLogOnError) {
            //     console.error(err)
            // }
            //
            // if (
            //     err.response &&
            //     err.response.data &&
            //     err.response.data.error &&
            //     err.response.data.error.message
            // ) {
            //     CustomToast(err.response.data.error.message, 'error')
            // }
            throw err
        })
}

export default APIService
