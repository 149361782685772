import ReactCodeInput from "react-code-input";
import React, {useEffect, useState} from "react";
import moment from "moment";
import Button from "../button/Button";
import _ from "lodash";

export default function CodeInput({
                                      isEmail,
                                      startAt,
                                      details,
                                      disabled,
                                      code,
                                      onResendTheCode,
                                      onCodeChange,
                                      onSubmitClick,
                                      actionProps
                                  }) {
    const [value, setValue] = React.useState(null);
    const isTimeOut = useTimeOut(startAt, isEmail ? 3 : 2)
    const isResendTimeOut = useTimeOut(startAt, isEmail ? 0.5 : 0.25)

    function resetCoed() {
        try {
            _.forEach(document.querySelector("#code .react-code-input").querySelectorAll("input"), el => {
                el.setAttribute("value", "")
                el.value = ""
            })
        } catch {
        }
    }

    return (<div
        className={`d-flex flex-column col-12 col-md-10 align-self-center align-items-center londonistLoginModalCodeInput`}>
        {details}
        <div
            id={"code"}>
            <ReactCodeInput
                disabled={disabled || isTimeOut}
                inputMode={'numeric'}
                autoFocus={true}
                type="one-time-code"
                fields={6}
                name={'code'}
                autoComplete="off"
                onChange={onCodeChange}/>
            <span className={"w-100 justify-content-center d-flex pt-3 pb-4"}>
                    code expires in:
                    <Counter
                        startAt={startAt}
                        isEmail={isEmail}/>
                </span>
            {!isTimeOut && <div className={'w-100 mt-4'}>
                <Button
                    className={'w-100 text-capitalize '}
                    onClick={onSubmitClick}
                    {...actionProps}
                    disabled={actionProps?.disabled || code.length !== 6}>
                    Verify
                </Button>
            </div>}
            <div
                className={`h6  mt-4 transition-300 ${isTimeOut ? 'shake_twice text-danger' : 'color_444444'} ${isResendTimeOut ? '' : 'opacity-0'}`}>
                {isTimeOut ? 'Verification code has expired: ' : "Didn't receive the code?"}
                <span
                    role={`${isResendTimeOut ? 'button' : ''}`}
                    className={`ms-1 ${isTimeOut ? 'fw-bold' : ''} ${isResendTimeOut ? 'color_primary' : 'color_444444'} `}
                    onClick={() => {
                        if (!isResendTimeOut) return
                        onResendTheCode()
                        setTimeout(() => {
                            resetCoed()
                        }, 1000)
                    }}>
                        resend new code
                    </span>
            </div>
        </div>
    </div>)
}

function useTimeOut(startAt, timeoutMinutes) {
    const [state, setState] = useState(false)

    useEffect(() => {
        setState(false)
        const end = new Date(startAt?.getTime() + (timeoutMinutes * 60000))
        const interval = setInterval(() => {
            if (end?.getTime() > new Date()?.getTime()) return
            setState(true)
            clearInterval(interval)
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [startAt])
    return state
}

function Counter({startAt, isEmail}) {
    const [counter, setCounter] = useState('')
    useEffect(() => {
        if (!startAt) return
        const end = moment(startAt).add(isEmail ? 3 : 2, 'minute')
        const interval = setInterval(() => {
            const start = moment()
            const diff = end.diff(start, 'seconds')
            if (diff < 0) {
                clearInterval(interval)
                return
            }
            moment.utc(diff * 1000).format("mm:ss")
            setCounter(moment.utc(diff * 1000).format("mm:ss"))
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [startAt])


    return (<span className={"color_primary ms-2"}>{counter}</span>)
}
